import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { of } from 'rxjs';
import { FullTimesheet } from '../Models/Timesheet';
import { Invoice } from '../Models/Invoice';
import { ICandidateDashboard } from '../Models/CandidateDashboard';
import { CandidateTimeSheet, TimesheetEdit } from '../Models/CandidateTimeSheet';
import { IBooking } from '../Models/Booking';
import { IJobinvite } from '../Models/Jobinvite';
import { AuthService } from '../auth/auth.service';
import { User } from '../Models/User';
import { AccountCandidate, CandidateMobile, CandidatePhoto, IManageCandidate, SetCandidatePhotoMobile } from '../Models/Candidate';
import { ICandidateLoop } from '../Models/CandidateLoop';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  baseUrl = environment.apiUrl;
  private candidateDashboardSource = new BehaviorSubject<ICandidateDashboard>(null);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  candidateDashboard$ = this.candidateDashboardSource.asObservable();
  private jobInviteSource = new BehaviorSubject<IJobinvite[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  jobInviteSource$ = this.jobInviteSource.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  _bookings: IBooking[] =  [];
  // eslint-disable-next-line @typescript-eslint/member-ordering
  user: User;
  private candidateLoop: ICandidateLoop;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
  }
  getJobInvite(): Observable<IJobinvite[]> {
    return this.http.get<IJobinvite[]>(this.baseUrl + `Jobinvites/GetJobinvitesByUser`);
  }
  getBookings(): Observable<IBooking[]> {

    return this.http.get<IBooking[]>(this.baseUrl +`Candidates/GetCandidateBookingstest/${this.user.id}`)
    .pipe(
      map(result => {
      // eslint-disable-next-line no-underscore-dangle
      this._bookings = result;
      return result;
    })
    );
  }
  getBooking(id: number): Observable<IBooking> {

     // eslint-disable-next-line no-underscore-dangle
     const findBooking =   {...this._bookings.find(bo => bo.id === id)};
     if (findBooking) {
       return of(findBooking);
     } else {
      return of(null);
     }
  }
  getVacancy(id: number): Observable<IJobinvite> {
    const jobsInvites =  [...this.jobInviteSource.getValue()];
    const findJobInvite = jobsInvites.find(x => x.id === id);
    if (findJobInvite) {
      return of(findJobInvite);
    }
    // return this.http.get<IJobinvite[]>(this.baseUrl + `Candidates/GetJobinvitestest`)
    // .pipe(
    //   map(result => {
    //     this.jobInviteSource.next(result);
    //     return result;
    //   })
    // );
  }
  getVacancies(): Observable<IJobinvite[]> {
    return this.http.get<IJobinvite[]>(this.baseUrl + `Candidates/GetJobinvitestest/${this.user.id}`)
    .pipe(
      map(result => {
        this.jobInviteSource.next(result);
        return result;
      })
    );
  }
  getTimeSheets(): Observable<FullTimesheet> {
    return this.http.get<FullTimesheet>(this.baseUrl + `Candidates/GetTimesheetsTest/${this.user.id}`);
  }
  getInvoice(): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(this.baseUrl + `Candidates/GetInvoices`);
  }
  getDashboard(): Observable<ICandidateDashboard> {
    return this.http.get<ICandidateDashboard>(this.baseUrl + `Candidates/GetDashboard`)
    .pipe(
      map(result => {
        this.candidateDashboardSource.next(result);
        return result;

      }));
  }
  getTimeSheetForedit(id: number): Observable<TimesheetEdit> {
    return this.http.get<TimesheetEdit>(this.baseUrl + `Timesheets/GetTimesheetById/${id}`);
  }
  getCandidateTimeSheet(id: number): Observable<CandidateTimeSheet> {
    return this.http.get<CandidateTimeSheet>(this.baseUrl + `Candidates/GetAgencyJobInvoices/${id}`);
  }
  getTimeSheet(): Observable<CandidateTimeSheet> {
    return this.http.get<CandidateTimeSheet>(this.baseUrl + `Candidates/GetAgencyJobInvoices`);
  }
  createInvoice(id: number): Observable<CandidateTimeSheet> {
    return this.http.get<CandidateTimeSheet>(this.baseUrl + `Candidates/GenerateInvoice/${id}`);
  }
  getManageCandidatesById(): Observable<IManageCandidate>  {
    return this.http.get<IManageCandidate>(this.baseUrl + `Candidates/GetCurrentCandidate`);
  }
  getCandidatesById(): Observable<CandidateMobile>  {
    return this.http.get<CandidateMobile>(this.baseUrl + `Candidates/GetCandidateMobile`);
  }
  uploadPhoto(document): Observable<CandidatePhoto> {
    return this.http.post<CandidatePhoto>(this.baseUrl + `Candidates/AddCandidatePhotoMobile/`, document);
  }
  setPhoto(photo: SetCandidatePhotoMobile): Observable<CandidatePhoto> {
    return this.http.put<CandidatePhoto>(this.baseUrl + `Candidates/set-main-photo/`, photo);
  }
  deletePhoto(id: number): Observable<CandidatePhoto> {
    return this.http.delete<CandidatePhoto>(this.baseUrl + `Candidates/DeleteCandidatePhoto/${id}`);
  }
  getCandidateLoop(): Observable<ICandidateLoop> {
    if (this.candidateLoop != null) {
      return of (this.candidateLoop);
    }
    return this.http.get<ICandidateLoop>(this.baseUrl + `Utiles/GetCandidatesUtilLoop`)
    .pipe(
      map(resultUtils => {
        this.candidateLoop = resultUtils;
        return this.candidateLoop;
      })
      );
  }
  registerNewCandidates(cand: AccountCandidate) {
    return this.http.post(this.baseUrl + 'Account/registerNewCandidates', cand).pipe(
      map((user: User) => true)
    );
  }
}
