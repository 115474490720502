import { BookingsService } from './../../../services/bookings.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { IBooking } from 'src/app/Models/Booking';
import { Geolocation } from '@capacitor/geolocation';
import { alertController } from '@ionic/core';

declare let google: any;

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.page.html',
  styleUrls: ['./booking-detail.page.scss'],
})
export class BookingDetailPage implements OnInit {
  @ViewChild('map', {read: ElementRef, static: false}) mapRef: ElementRef;
  map: any;

  booking: IBooking;
  infoWindow: any = [];
  markers: any = [];
  constructor(
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private candidateService: BookingsService) { }

  ngOnInit() {
   this.route.paramMap.subscribe(param => {
     if (!param.has('bookingId')) {
       this.navCtrl.navigateBack('/candidates/bookings');
       return;
     }
     this.candidateService.getBooking(+param.get('bookingId')).subscribe(result => {
       // eslint-disable-next-line no-debugger
       //debugger;
       this.booking = result;
       const jobLocation = {
        title: 'Job Location',
        latitude: result.lat,
        longitude: result.lng
      };
      const myLocation = {
        title: 'My Location',
        latitude: result.myLat,
        longitude: result.myLng
      };
      this.markers.push(jobLocation);
      this.markers.push(myLocation);
     });
   });
  }
  ionViewDidEnter() {
    this.showMap();
  }
  showMap(): void {
    const location = new google.maps.LatLng(parseFloat(this.booking.lat), parseFloat(this.booking.lng));
    const options = {
      center: location,
      zoom: 15,
      disableDefaultUI: true
    };
    this.map = new google.maps.Map(this.mapRef.nativeElement, options);
    this.addMarkerToMap(this.markers);
  }
  addMarkerToMap(markers): void {
   for(const marker of markers) {
      const position = new google.maps.LatLng(marker.latitude, marker.longitude);
      const mapMarker = new google.maps.Marker({
        position,
        title: marker.title,
        latitude: marker.latitude,
        longitude: marker.longitude
      });
      mapMarker.setMap(this.map);
      this.addInfoWindowsToMarker(mapMarker);
    }

  }
  addInfoWindowsToMarker(marker) {
   const infoContent = '<div id="content">'+
                       '<h2 ="firstHeading" class="firstHeading">' + marker.title + '</h2>' +
                       '<p>Latitude' + marker.latitude + '</p>' +
                       '<p>Longitude' + marker.longitude + '</p>' +
                    '</div>';
     const infoWin = new google.maps.InfoWindow({
      content: infoContent
    });
    marker.addListener('click', () => {
      this.closeAllWindow();
      infoWin.open(this.map, marker);
    });
    this.infoWindow.push(infoWin);
  }
  // getCurrentLocation = async () => {
  confirmJonLocation = async () => {
    // eslint-disable-next-line no-debugger
    debugger;
    const alert =  await alertController.create({
      header: 'Confirm you current Location',
      message: 'This is to confirm that you are at job location',
      buttons: [
        {
          text: 'Cancel',
          role:'cancel',
          cssClass: 'secundary',
          handler: () => {
          }
        },
        {
          text: 'Ok',
          handler: () => {
            this.updateBookings();
          }
        }
      ]
    });
    await alert.present();
  };
  updateBookings= async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    this.booking.comfirmArLat = coordinates.coords.latitude.toString();
    this.booking.comfirmArLng = coordinates.coords.longitude.toString();
    this.booking.isConfirmedArriveed = true;
    this.candidateService.updateBook(this.booking).subscribe(result => {
      this.booking.isConfirmedArriveed = true;
    });
  };

  closeAllWindow() {
    for(const window of this.infoWindow) {
      window.close();
    }
  }

}
