/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { of } from 'rxjs';
import { IJobinvite, MobileVacancies } from '../Models/Jobinvite';


@Injectable({
  providedIn: 'root'
})
export class VacanciesService {
  baseUrl = environment.apiUrl;
  private vacanciesSource = new BehaviorSubject<MobileVacancies>(null);
  vacancies$ = this.vacanciesSource.asObservable();

  constructor(private http: HttpClient) {}
  getJobInvite(): Observable<MobileVacancies[]> {
    return this.http.get<MobileVacancies[]>(this.baseUrl + `Jobinvites/GetJobinvitesByUser`);
  }

  getVacancy(id: number): Observable<IJobinvite> {
    const newVacancies = [...this.vacanciesSource.value.newVacancies];
    const vacancynew =  newVacancies.find(x => x.id === id);
    if(vacancynew) {
      return of(vacancynew);
    }
    const pastVacancies = [...this.vacanciesSource.value.pastVacancies];
    const vacancypast =  pastVacancies.find(x => x.id === id);
    if(vacancypast) {
      return of(vacancypast);
    }
    return this.http.get<IJobinvite>(this.baseUrl + `Candidates/GetVacancy/${id}`);
  }
  getVacancies(): Observable<MobileVacancies> {
    return this.http.get<MobileVacancies>(this.baseUrl + `Candidates/GetMobileVicancies`)
    .pipe(
      map(result => {
        this.vacanciesSource.next(result);
        return result;
      })
    );
  }
  bookJobInvite(jobinvite: IJobinvite) {
    const bookJob = {
      candidateId: jobinvite.candidateId,
      vacancyId: jobinvite.vacancyId,
      jobInviteId: jobinvite.id
    };
    return this.http.post(this.baseUrl + `Bookings/CandidateBooking`, bookJob);
  }
}
