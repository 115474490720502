/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { CandidateDocumentRequired, ICandidate, IManageCandidate } from '../Models/Candidate';
import { User } from '../Models/User';
import { Http } from '@capacitor-community/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  baseUrl = environment.apiUrl;
  private candidateDocumentSource = new BehaviorSubject<CandidateDocumentRequired[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  candidateDocumentSource$ = this.candidateDocumentSource.asObservable();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  user: User;
  constructor(private http: HttpClient, private authServices: AuthService ) {
    this.authServices.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
   }
  getSelectDocument(id: number) {
    const candidateDocs=   [...this.candidateDocumentSource.value];
    const documentRequired = candidateDocs.find(dr => dr.id === id);

    if (documentRequired) {
      documentRequired.issueDate = new Date(documentRequired.issueDate);
      documentRequired.expireDate = new Date(documentRequired.expireDate);
      return of(documentRequired);
    } else {
     return of(null);
    }
  }
  getManageCandidatesById(): Observable<CandidateDocumentRequired[]>  {
    // eslint-disable-next-line no-debugger
    // debugger;
    return this.http.get<CandidateDocumentRequired[]>(this.baseUrl + `Candidates/GetCandidateDocs`)
    .pipe(
      map(result => {
        this.candidateDocumentSource.next(result);
        return result;
      })
    );
  }
  getCandidateSDocs(): Observable<CandidateDocumentRequired[]> {
    return from(Http.request({
      method: 'GET',
      url: this.baseUrl +`Candidates/GetCandidateDocs`,
      headers: {'content-type' : 'application/json',
      Authorization : `Bearer ${ this.user.userToken}`},
    })).pipe(
      map(result => {
        const dockReq = result.data as CandidateDocumentRequired[];
        this.candidateDocumentSource.next(dockReq);
        return dockReq;
      }));
  }
  afterUpdate(docReq: CandidateDocumentRequired) {
    // eslint-disable-next-line no-debugger
    //debugger;
    const candidateDocs=   [...this.candidateDocumentSource.value];
    const index = candidateDocs.findIndex(dr => dr.id === docReq.id);
    candidateDocs[index] = docReq;
    this.candidateDocumentSource.next([...candidateDocs]);
  }
  updateDocument(document): Observable<CandidateDocumentRequired> {
    return this.http.post<CandidateDocumentRequired>(this.baseUrl + `CandidateDocuments/UpdateRequireDoc/`, document);
  }
}
